// @flow
import * as React from 'react';


const NavigationFinances = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.7234 11.6508C11.0484 12.3258 10.1485 12.7008 9.17347 12.7008C6.99844 12.7008 5.27344 10.8258 5.27344 8.50077C5.27344 6.17578 6.99844 4.30078 9.17347 4.30078C10.1485 4.30078 11.0484 4.67578 11.7234 5.35078" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 7.60156H8.875" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 9.39844H8.875" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default NavigationFinances