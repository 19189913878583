import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserService } from "services/api";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { UserRequestsListResponse, UsersListResponse, UserStatsResponse } from "./user_types";
import { User } from "views/auth/logic/auth_types";
import StatisticsService from "services/api/StatisticsService";

export type UserCrudType = ['users', number, string, string]

export const default_users_key: UserCrudType = ['users', 1, '', 'date_added'];
export const get_detail_user_key = (uuid: string) => ['user_details', uuid];

export const payload_users_key = ['users_current_key'];
export const payload_user_requests_key = ['user_requests_current_key'];

export const useUsers = (page: number, keyword: string, order_field: string = 'date_added', isMandant?: boolean, safe_access?: string) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const current_key = ['users', page, keyword, order_field];

    queryClient.setQueryData(payload_users_key, current_key);

    return useQuery<UsersListResponse, Error>(
        current_key,
        async ({ pageParam, queryKey: [key, page] }) => {
            let data: any;
            try {
                const response = await UserService.get_all_user({
                    page: (page as number),
                    keyword: (keyword as string),
                    per_page: 9,
                })
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('internet_connection_error'))
            }
            throw new Error(data?.message || data?.detail || t('internet_connection_error'));
        },
    )
}

export const useUserRequests = (page: number, keyword: string, status?: string, user_public_id?: string) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const current_key = ['user-requests', page, keyword, status, user_public_id];

    queryClient.setQueryData(payload_user_requests_key, current_key);

    return useQuery<UserRequestsListResponse, Error>(
        current_key,
        async ({ pageParam, queryKey: [key, page] }) => {
            let data: any;
            console.log('id', user_public_id)
            try {
                const response = await UserService.get_all_user_requests({
                    page: (page as number),
                    keyword: (keyword as string),
                    per_page: 9,
                    status,
                    user_public_id: (user_public_id as string)
                })
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('internet_connection_error'))
            }
            throw new Error(data?.message || data?.detail || t('internet_connection_error'));
        },
    )
}

export const useDeleteUserMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        async (uuid: string) => {
            setLoading(true);
            let data;
            try {
                const response = await UserService.delete_user(uuid);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return { uuid };
                }
            } catch (e: any) {
                console.log('error:', e)
                throw new Error(t('internet_connection_error'))
            }
            throw new Error(data?.message || data?.detail || t('internet_connection_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: { uuid: string }) => {
                const key = queryClient.getQueryData<UserCrudType>(payload_users_key) || default_users_key;

                queryClient.setQueryData<UsersListResponse>(key, (old) => {
                    const users = old || default_users;

                    return {
                        ...users,
                        data: [...(old?.data || [])].filter(user => user.public_id !== data.uuid)
                    }
                });

                enqueueSnackbar(t('user_page.delete_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useBlocOrActiveUserMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        async (id: string) => {
            setLoading(true);
            let data;
            try {
                const response = await UserService.bloc_unblock_user(id);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('internet_connection_error'))
            }
            throw new Error(data?.message || data?.detail || t('internet_connection_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: any) => {
                const key = queryClient.getQueryData<UserCrudType>(payload_users_key) || default_users_key;

                queryClient.setQueryData<UsersListResponse>(key, (old) => {
                    const users = old || default_users;

                    return {
                        ...users,
                        data: [...(old?.data || [])].map(user => user.public_id === data.public_id ? { ...user, status: user?.status === 'BLOCKED' ? 'ACTIVED' : 'BLOCKED' } : user)
                    }
                });

                enqueueSnackbar(data.message, {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useUserDetails = (uuid: string) => {
    const { t } = useTranslation();
    return useQuery<User, Error>(
        get_detail_user_key(uuid),
        async ({ pageParam, queryKey: [key, page] }) => {
            let data: any;
            if (!uuid)
                throw new Error(t('UUID not found'))
            try {
                const response = await UserService.get_user_details(uuid);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('internet_connection_error'))
            }
            throw new Error(data?.message || data?.detail || t('internet_connection_error'));
        },
    )
}

const default_users: UsersListResponse = {
    pages: 1,
    data: [],
    current_page: 1,
    total: 10,
    page: 1,
    per_page: 10,
}

// Stats


export type StatsUserType = "requests-sent" | 'treated-services' | "created-services" | "anfrage-erhalten" | "anfrage-abgelehnt" | "anfrage-abgeschlossen"

export const useUserRequestsStats = (category: StatsUserType, year: number, user_public_id: string) => {
    const { t } = useTranslation();
    const current_key = ['users_total_stats', category, year, user_public_id];

    return useQuery<UserStatsResponse, Error>(
        current_key,
        async () => {
            let data: any;
            try {
                const response = await StatisticsService.get_user_request_stats(category, year, user_public_id);

                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('internet_connection_error'))
            }
            throw new Error(data?.message || data?.detail || t('internet_connection_error'));
        },
    )
}


export const useUSerMonthRequestSummary = (year: number, user_public_id: string) => {
    const { t } = useTranslation();
    const current_key = ['page_user_details_month_request_summary_key', year, user_public_id];

    return useQuery<number[], Error>(
        current_key,
        async () => {
            let data: any;
            try {
                const response = await StatisticsService.get_user_requests_per_month(year, user_public_id);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('internet_connection_error'))
            }
            throw new Error(data?.message || data?.detail || t('internet_connection_error'));
        },
    )
}