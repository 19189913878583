import React, {useEffect, useState} from 'react';
import {ErrorHandler} from 'components';
import styles from './Application.module.css';
import {useTranslation} from "react-i18next";
import Navbar from "./components/Navbar";
import {Header} from "./components/Header";
import io from 'socket.io-client';
import {UserContext, UserContextType} from "views/auth/context/UserContext";
import config from "config";


const Application = (props: any) => {
    const {children} = props;
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const {auth} = React.useContext(UserContext) as UserContextType

    const {t} = useTranslation();

    const handleOpenMenu = () => {
        setMobileNavOpen(true);
    }
    const handleCloseMenu = () => {
        setMobileNavOpen(false);
    }

    useEffect(() => {

        const socket = io(config.socket_url, {transports: ['websocket']});

        socket.on('connect', () => {
            console.log("socket connected")
        });

        socket.emit('EVENT_FIRST_CONNECT', auth?.token ?? "");
        socket.emit('EVENT_JOIN_CHANNEL', `${auth?.user?.public_id ?? ""}`);

        socket.on('disconnect', () => {
            console.log("Disconnect from socket")
        });

        //@ts-ignore
        window.socket = socket;
        return () => {
            socket.off('connect');
            socket.off('disconnect');
        };
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.layout}>
                <div className={styles.wrapper}>
                    <Navbar open={isMobileNavOpen} onClose={handleCloseMenu}/>
                    <div className={styles.content}>
                        <Header handleOpenMenu={handleOpenMenu}/>
                        <ErrorHandler>{children}</ErrorHandler>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Application;
