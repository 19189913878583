// @flow
import * as React from 'react';


const NavigationHome = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6L1 12C1 12.5523 1.44772 13 2 13H11C11.5523 13 12 12.5523 12 12V6C12 5.44772 11.5523 5 11 5L2 5C1.44772 5 1 5.44772 1 6Z" stroke="#282B89" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 2V12C15 12.5523 15.4477 13 16 13H20C20.5523 13 21 12.5523 21 12V2C21 1.44771 20.5523 1 20 1H16C15.4477 1 15 1.44771 15 2Z" stroke="#282B89" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 17V20C7 20.5523 7.44772 21 8 21H19C19.5523 21 20 20.5523 20 20V17C20 16.4477 19.5523 16 19 16H8C7.44772 16 7 16.4477 7 17Z" stroke="#282B89" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default NavigationHome