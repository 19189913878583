/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Fragment, lazy, Suspense } from 'react';
import AuthLayout from 'layouts/Auth';
import ApplicationLayout from 'layouts/Application';
import { RouteConfig } from 'react-router-config';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingScreen } from 'components';
import AuthGuard from './AuthGuard';
import GuestGuard from './GuestGuard';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

const routesConfig: RouteConfig[] = [
    {
        path: '/',
        exact: true,
        component: lazy(() => import('routes/Router')),
    },
    {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/errors/error.page')),
    },
    {
        path: '/auth',
        layout: AuthLayout,
        guard: GuestGuard,
        routes: [
            {
                path: '/auth/login',
                exact: true,
                component: lazy(() => import('views/auth/Login.page'))
            },
            {
                path: '/auth/password-recovery',
                exact: true,
                component: lazy(() => import('views/auth/recovery/GetUserMail.page')),
            },
            {
                path: '/auth/password-recovery/:email',
                exact: true,
                component: lazy(() => import('views/auth/recovery/ValidateResetPassword.page')),
            },
            {
                path: '/auth/password-recovery/:email/:code',
                exact: true,
                component: lazy(() => import('views/auth/recovery/NewPassword.page')),
            },
            {
                path: '*',
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/app',
        layout: ApplicationLayout,
        guard: AuthGuard,
        routes: [
            {
                path: '/app/error-404',
                exact: true,
                component: lazy(() => import('views/errors/error.page')),
            },
            {
                path: '/app/home',
                exact: true,
                component: lazy(() => import('views/home/Home.page')),
            },
            {
                path: '/app/statistics',
                exact: true,
                component: lazy(() => import('views/statistics/Statistic.page')),
            },
            {
                path: '/app/users',
                exact: true,
                component: lazy(() => import('views/users/Users.page')),
            },
            {
                path: '/app/users/:uuid/:tab',
                exact: true,
                component: lazy(() => import('views/users/details/Details.page')),
            },
            {
                path: '/app/messages',
                exact: true,
                component: lazy(() => import('views/messages/Message.page')),
            },
            {
                path: '/app/messages/:uuid',
                exact: true,
                component: lazy(() => import('views/messages/Message.page')),
            },
            {
                path: '/app/profile',
                exact: true,
                component: lazy(() => import('views/profile/Profile.page')),
            },
            {
                path: '/app/request',
                exact: true,
                component: lazy(() => import('views/requests/Requests.page')),
            },
            {
                path: '/app/services',
                exact: true,
                component: lazy(() => import('views/services/Services.page')),
            },
        ]
    },
    {
        path: '*',
        component: () => <Redirect to="/app/error-404" />
    }
];

const renderRoutes = (routes: RouteConfig[]) =>
    routes ? (
        <Suspense fallback={<LoadingScreen isDashboard />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component: any = route.component;
                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null;

function Routes() {
    const { i18n } = useTranslation();
    moment.locale(i18n.language);
    moment.tz('Europe/Berlin');
    return renderRoutes(routesConfig);
}

export default Routes;
