import React, {useState} from 'react';
import {Box, Dialog, DialogContent, DialogTitle, Grid, Slide, Stack, Typography} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {FormikTextField} from "form/FormikTextField";
import ButtonLoading from "../ButtonLoading";
import moment from "moment/moment";
import {FormikDatePickerField} from "form/FormikDatePickerField";
import {FormikSelectField} from "form/FormikSelectField";
import {User} from "../../views/auth/logic/auth_types";
import {useAddOrUpdateUser} from "./logic/hook";

interface AddOrUpdateUserProps {
    open: boolean;
    onClose: any;
    data?: User
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddOrUpdateUser = (props: AddOrUpdateUserProps) => {
    const {open, onClose, data} = props;
    const {t} = useTranslation();

    const [activeRole, setActiveRole] = useState(data?.role?.id ?? 1);

    const validations = {
        firstname: Yup.string()
            .typeError(t('global_field_require'))
            .required(t('global_field_require')),
        lastname: Yup.string()
            .typeError(t('global_field_require'))
            .required(t('global_field_require')),
        birthday: Yup.string()
            .typeError(t('global_field_require'))
            .required(t('global_field_require')),
        email: Yup.string()
            .typeError(t('global_field_require'))
            .email(t('global_field_email'))
            .required(t('global_field_require')),
        gender: Yup.string()
            .typeError(t('global_field_require'))
            .required(t('global_field_require')),
        civility_title: Yup.string()
            .typeError(t('global_field_require'))
            .required(t('global_field_require')),
        ...(!data ? {
            password: Yup.string().required(t("global_field_require")),
        } : {}),
        ...(activeRole != 1 ? {
            user_location: Yup.string()
                .typeError(t('global_field_require'))
                .required(t('global_field_require')),
            user_postal_code: Yup.string()
                .typeError(t('global_field_require'))
                .required(t('global_field_require')),
            user_street_nr: Yup.string()
                .typeError(t('global_field_require'))
                .required(t('global_field_require')),
            user_street: Yup.string()
                .typeError(t('global_field_require'))
                .required(t('global_field_require')),
        } : {})
    }

    const initialValue = {
        ...(!data ? {
            password: "",
        } : {}),
        email: data?.email ?? "",
        civility_title: data?.civility_title ?? "",
        gender: data?.gender ?? "",
        lastname: data?.lastname ?? "",
        firstname: data?.firstname ?? "",
        user_street: data?.address?.street ?? "",
        user_street_nr: data?.address?.street_nr ?? "",
        user_postal_code: data?.address?.postal_code ?? "",
        user_location: data?.address?.location ?? "",
        birthday: data?.birthday ? moment(new Date()).format("YYYY-MM-DD") : moment(new Date()).subtract(18, 'year').format("YYYY-MM-DD")
    };

    const [loading, handleSubmit] = useAddOrUpdateUser();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            fullWidth
            maxWidth={"sm"}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "5px !important",
                }
            }}
        >
            <DialogTitle
                bgcolor={"#181835"}
                color={"#fff"}
            >
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant={"h5"}>
                        {t('add_user_modal_title')}
                    </Typography>
                    <Box
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}
                        onClick={() => onClose()}
                    >
                        <img src="/icons/close.svg" alt=""/>
                    </Box>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction={{
                        sm: "row",
                        xs: "column",
                    }}
                    gap={1}
                    alignItems={"stretch"}
                    justifyContent={"space-between"}
                    sx={{
                        my: 5
                    }}
                >
                    {
                        [
                            {
                                label: t('label_admin'),
                                color: "#4A4FDD",
                                role: 1
                            },
                            {
                                label: "Senior",
                                color: "#29CB39",
                                role: 6
                            },
                            {
                                label: "Helfer",
                                color: "#FF6F00",
                                role: 2
                            },
                        ].map((item, index) => (
                            <Box
                                key={`${item.color}_${index}`}
                                sx={{
                                    borderRadius: "5px",
                                    border: (activeRole != item.role) ? "1px solid #6C6965" : "3px solid transparent",
                                    backgroundColor: (activeRole != item.role) ? "transparent" : item.color,
                                    cursor: data ? "default" : "pointer",
                                    p: 1.5,
                                    width: '100%'
                                }}
                                onClick={() => {
                                    if (!data) {
                                        setActiveRole(item.role)
                                    }
                                }}
                            >
                                <Typography
                                    variant={"body1"}
                                    align={"center"}
                                    color={(activeRole != item.role) ? "#9F9996" : "#fff"}
                                >
                                    {item.label}
                                </Typography>
                            </Box>
                        ))
                    }
                </Stack>

                <Formik
                    initialValues={initialValue}
                    validationSchema={Yup.object().shape(validations)}
                    onSubmit={(values, formikHelpers) => {
                        let payload: any = {...values};
                        payload = {
                            ...payload,
                            role_id: activeRole,
                            address: {
                                street: payload.user_street,
                                location: payload.user_location,
                                street_nr: payload.user_street_nr,
                                postal_code: payload.user_postal_code,
                            }
                        }
                        delete payload.user_location;
                        delete payload.user_postal_code;
                        delete payload.user_street_nr;
                        delete payload.user_street;

                        if (data) {
                            payload['public_id'] = data?.public_id ?? ""
                        }
                        if (activeRole == 2) {
                            payload['account_type'] = "EHRENAMTLICH"
                        }
                        handleSubmit(payload, () => onClose());
                    }}>
                    {({
                          setFieldTouched,
                          handleSubmit,
                          setFieldValue,
                          errors,
                      }) =>
                        <Form onSubmit={handleSubmit} className={'form'}>
                            <Grid container spacing={2}>
                                <FormikTextField
                                    xs={12}
                                    autoFocus
                                    variant={'standard'}
                                    label={t('user_detail_page.firstname')}
                                    name={'firstname'}
                                />
                                <FormikTextField
                                    xs={12}
                                    variant={'standard'}
                                    label={t('user_detail_page.lastname')}
                                    name={'lastname'}
                                />

                                <FormikDatePickerField
                                    value={data?.birthday ? moment(new Date()).format("YYYY-MM-DD") : moment(new Date()).subtract(18, 'year').format("YYYY-MM-DD")}
                                    xs={12}
                                    variant={'standard'}
                                    label={t('user_detail_page.birthday')}
                                    name={'birthday'}
                                    onChange={(date) => {
                                        setFieldValue("birthday", date);
                                    }}
                                />
                                <FormikTextField
                                    xs={12}
                                    variant={'standard'}
                                    label={t('user_detail_page.email')}
                                    name={'email'}
                                />

                                <FormikSelectField
                                    xs={12}
                                    variant={'standard'}
                                    label={t('user_detail_page.civility')}
                                    name={'gender'}
                                    option={[
                                        {
                                            label: t("label_gender_WEIBLICH"),
                                            value: "WEIBLICH"
                                        },
                                        {
                                            label: t("label_gender_MÄNNLICH"),
                                            value: "MÄNNLICH"
                                        },
                                        {
                                            label: t("label_gender_ANDERE"),
                                            value: "ANDERE"
                                        },
                                    ]}
                                />

                                <FormikSelectField
                                    xs={12}
                                    variant={'standard'}
                                    label={t('user_detail_page.gender')}
                                    name={'civility_title'}
                                    option={
                                        [
                                            {
                                                label: t("label_civility_HERR"),
                                                value: "HERR"
                                            },
                                            {
                                                label: t("label_civility_FRAU"),
                                                value: "FRAU"
                                            },
                                            {
                                                label: t("label_civility_ANDERE"),
                                                value: "ANDERE"
                                            },
                                        ]
                                    }
                                />

                                {
                                    activeRole != 1 &&
                                    <>
                                        <FormikTextField
                                            xs={8}
                                            variant={'standard'}
                                            label={t('label_street')}
                                            name={'user_street'}
                                        />
                                        <FormikTextField
                                            xs={4}
                                            variant={'standard'}
                                            label={t('label_street_nr')}
                                            name={'user_street_nr'}
                                        />
                                        <FormikTextField
                                            xs={4}
                                            variant={'standard'}
                                            label={t('label_postal_code')}
                                            name={'user_postal_code'}
                                        />
                                        <FormikTextField
                                            xs={8}
                                            variant={'standard'}
                                            label={t('label_location')}
                                            name={'user_location'}
                                        />
                                    </>
                                }

                                {
                                    !data &&
                                    <FormikTextField
                                        xs={12}
                                        type={'password'}
                                        variant={'standard'}
                                        label={t('password_user')}
                                        name={'password'}
                                    />
                                }
                                <Grid item xs={12}>
                                    <ButtonLoading
                                        loading={loading}
                                        fullWidth
                                        type={"submit"}
                                        onClick={(e) => {
                                            Object.keys(initialValue)
                                                .forEach(field => {
                                                    setFieldTouched(field, true);
                                                });
                                        }}
                                        variant={'contained'}
                                        color={'success'}
                                    >
                                        {t('global_label_save')}
                                    </ButtonLoading>
                                </Grid>
                            </Grid>
                        </Form>
                    }
                </Formik>
            </DialogContent>
        </Dialog>
    )
}


export default AddOrUpdateUser