// @flow
import * as React from 'react';


const NavigationAnfrangen = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.31 21H5.69C4.71 21 3.88 20.29 3.72 19.33L2 9H20L18.28 19.33C18.12 20.29 17.28 21 16.31 21Z" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 9H21" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 13H16" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 17H15" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 9H10V1.5C10 1.22 10.22 1 10.5 1H11.5C11.78 1 12 1.22 12 1.5V9Z" stroke="#6C6965" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default NavigationAnfrangen