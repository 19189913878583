import * as React from 'react';
import {useState} from 'react';
import styles from "../Application.module.css";
import navigation_config, {Menu} from "../navigation_config";
import {Box, Button, ButtonBase, Drawer, Stack} from "@mui/material";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import useResponsive from "hooks/useResponsive";
import {UserContext, UserContextType} from "views/auth/context/UserContext";
import LogoutIcon from 'assets/logout';
import {AddOrUpdateUser} from "components";


interface NavbarProps {
    open: boolean
    onClose: () => void
}

const Navbar = (props: NavbarProps) => {
    const history = useHistory();
    const isDesktop = useResponsive('up', 'lg');
    const isMobile = useResponsive('down', 'sm');
    const [addUser, setAddUser] = useState(false);
    const {logout} = React.useContext(UserContext) as UserContextType
    const {t} = useTranslation();
    const handleLogout = async () => {
        logout();
        history.push("/");
    };

    const content = <Box
        className={styles.navbar}
        sx={{
            width: {
                xs: "100vw",
                sm: "calc(100vw * 0.4)",
                lg: "unset"
            },
            backgroundColor: {
                xs: "#181835",
                md: "#fff"
            }
        }}
    >
        {
            addUser &&
            <AddOrUpdateUser
                open={addUser}
                onClose={() => setAddUser(false)}
            />
        }
        <Stack
            direction={"row"}
            justifyContent={isMobile ? "space-between" : "center"}
            alignItems={"center"}
            sx={{
                px: 2,
                py: 1,
                mb: 3
            }}
        >
            <img height={64} src={isMobile ? "/logo_horizontal.svg" : "/logo.svg"} alt=""/>

            {
                isMobile ? (
                    <Button
                        color={"primary"}
                        startIcon={<img src="/icons/add.svg" alt=""/>}
                        onClick={() => {
                            setAddUser(true)
                        }}
                    >
                        {t('btn_label_add_user')}
                    </Button>
                ) : (
                    <Box
                        onClick={props.onClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 24
                        }}
                    >
                        <img src="/icons/Close.svg" width={32} alt=""/>
                    </Box>
                )
            }
        </Stack>

        {
            navigation_config.map(menu => {

                return <MenuItem
                    key={menu.title}
                    onClick={() => {
                        history.push(menu.href)
                        props.onClose();
                    }}
                    menu={menu}
                />
            })
        }
        <Box sx={{
            bgcolor: '#CEE0E1',
            height: '1px',
            ml: '42px',
            mb: 4,
            mt: 2,
            mr: '20px'
        }}/>
        <MenuItem
            onClick={handleLogout}
            menu={{
                title: 'navigation_config_7',
                href: '#',
                icon: LogoutIcon
            }}
        />
    </Box>;

    return (
        isDesktop ? content : <Drawer
            sx={{
                position: 'relative',
                "& .MuiPaper-root": {
                    borderRadius: "0 !important"
                }

            }}
            open={props.open}
            onClose={props.onClose}

        >
            {content}

        </Drawer>
    );
};


type MenuItemProps = {
    menu: Menu
    onClick: () => void
};

export const MenuItem = (props: MenuItemProps) => {
    const {menu} = props;
    const history = useHistory();
    const {t} = useTranslation();

    const active = history.location.pathname === menu.href;
    const Icon = menu.icon;
    return (
        <ButtonBase
            key={menu.title}
            onClick={props.onClick}
            sx={{
                py: 2,
                fontWeight: active ? 600 : 400,
                display: 'flex',
                width: 'calc(100% - 43px - 20px)',
                borderRadius: '5px',
                pl: 2,
                backgroundColor: active ? '#E4E5F7' : 'transparent',
                color: active ? 'primary.main' : '#6C6965',
                transitionDuration: '350ms',
                ml: 3,
                alignItems: 'center',
                justifyContent: 'flex-start',
                '&:hover': {
                    color: '#282B89',
                    '& path': {
                        stroke: '#282B89',
                    },
                },
                '& path': {
                    stroke: active ? '#282B89' : '#6C6965',
                },
                'svg': {
                    width: '24px',
                    mr: 2,
                }
            }}>
            <Icon/>
            {t(menu.title)}
        </ButtonBase>
    );
};

export default Navbar